export default {
  "solar": {
    "boolean": {
      "false": "No",
      "true": "Yes"
    },
    "cancel": "Cancel",
    "dashboard": {
      "allVehicles": {
        "energyEarnings": "Energy Earnings",
        "installedCapacity": "Installed Capacity",
        "kWInstalled": "kW installed",
        "kWpInstalled": "kWp installed",
        "latestSignal": "Last update",
        "title": "All vehicles",
        "typeToSearch": "Search...",
        "vehicles": "Vehicles",
        "viewDetails": "View details"
      },
      "avgPower": "Avg. Energy Earnings",
      "backToAllVehicles": "Back to all vehicles",
      "dailyAverageEnergyEarnings": "Daily Average Energy Earnings",
      "dailyAverageUsage": "Daily Average Usage PV System",
      "demoDisclaimer": {
        "text": "Please note that the displayed data serves for demonstration purposes only and derives from our simulation models for a complete year of operation without the vehicle(s) out of service allowing the customers to see the possible energy earnings for each day of the year that the vehicle is fully operational with the PV system during daylight hours. Our simulations models have been validated using data from historic climate conditions and numerous installations on customer vehicles, operating in real-world driving environments. Actual energy yields may vary relative to an average year due to weather conditions. It's important to acknowledge that operation hours, driving conditions, vehicle health and the energy consumption specifics of the vehicle also influence the yield.",
        "title": "Values are for demonstration purposes only"
      },
      "details": {
        "dailyEnergyEarnings": {
          "all": "Select all",
          "editOperatingMode": "Edit",
          "filters": {
            "date": "Time range:",
            "kWh": "Energy earnings (kWh):",
            "title": "Filters ({{active}} active)",
            "to": "to"
          },
          "title": "Daily energy earnings"
        },
        "filters": {
          "addFilter": "Mark data on {{day}} as invalid",
          "editFleetFilter": "Data on {{day}} is marked as invalid for all vehicles",
          "editVehicleFilter": "Data on {{day}} is marked as invalid for {{vehicleName}}",
          "fleetFiltered": "Signals are marked as invalid for all vehicles",
          "markFleetInvalid": "Mark as invalid for all vehicles",
          "markValid": "Mark as valid",
          "markVehicleInvalid": "Mark as invalid for {{vehicleName}}",
          "notFiltered": "Signals are valid",
          "reason": "Enter reason",
          "updateReason": "Update reason",
          "vehicleFiltered": "Signals are marked as invalid for this vehicle"
        },
        "hourlyOutput": "Hourly power output",
        "hourlyOutputDay": "Select day:",
        "kWInstalled": "kW installed",
        "kWpInstalled": "kWp installed",
        "last30Days": "Daily energy earnings",
        "latestSignal": "Latest update",
        "operatingMode": {
          "reason": "Reason / comments (optional)",
          "title": "Operating mode for {{vehicleName}} on {{day}}",
          "titleMultipleDays": "Operating mode for {{vehicleName}} on selected days"
        },
        "title": "Details"
      },
      "downloadCsv": {
        "day": "Day",
        "energyEarningsWh": "Energy earnings (Wh)",
        "label": "CSV",
        "tooltip": "Download selected values as CSV file"
      },
      "duration": {
        "allTime": "All time",
        "month": "Month",
        "title": "Duration:",
        "year": "Year"
      },
      "energy": "Energy",
      "excluded": "Not included in the statistics",
      "hours": "hours",
      "includedOperatingModes": "Operating Modes:",
      "kWh": "kWh",
      "monthlyAverageEnergyEarnings": "Monthly Average Energy Earnings",
      "noVehicles": "There are no vehicles in the current fleet.",
      "peakEnergyEarnings": "Peak Energy Earnings",
      "summary": "Summary",
      "title": "Dashboard",
      "totalEnergyEarnings": "Total Energy Earnings",
      "vehicles": "Vehicles",
      "weeklyAverageEnergyEarnings": "Weekly Average Energy Earnings"
    },
    "date": "{{day}}/{{month}}/{{year}}",
    "documents": {
      "backToDashboard": "Back to dashboard",
      "docs": {
        "empty": "There is no documentation so far.",
        "title": "Documentation"
      },
      "reports": {
        "empty": "There are no reports so far.",
        "title": "Reports"
      }
    },
    "errors": {
      "confirm": "Ok",
      "errorLoadingData": {
        "text": "An unexpected error occured while loading the page. Please try again.",
        "title": "Error loading data"
      },
      "errorSavingData": {
        "text": "Your data could not be saved. Please try again.",
        "title": "Error saving data"
      },
      "loginError": {
        "text": "Could not log in. Please try again.",
        "title": "Error while logging in"
      },
      "showDetails": "Show details",
      "access_denied": {
        "title": "Access denied",
        "text": "You do not have permission to access this page."
      }
    },
    "euros": {
      "delimiter": ",",
      "format": "€{{amount}} ",
      "separator": "."
    },
    "faqs": {
      "edit": {
        "answer": "Answer ({{lang}})",
        "deleteButton": "Delete",
        "editGroup": "Edit group",
        "editQuestion": "Edit question",
        "groupTitle": "Title ({{lang}})",
        "question": "Question ({{lang}})"
      },
      "intro": "Below we’ve put together the most important information regarding the dashboard. We're currently in the midst of the development, therefore we ask for your understanding that details may still be subject to change.",
      "title": "Frequently Asked Questions"
    },
    "fleet": {
      "add": "Add fleet",
      "addButton": "Add",
      "deleteButton": "Delete",
      "editButton": "Edit",
      "fleets": {
        "add": "Add fleet",
        "edit": "Edit fleet",
        "fleetType": "Type",
        "fleetTypeBusKit": "Bus kit",
        "fleetTypeCampingworld": "Campingworld",
        "fleetTypeSvc3": "SVC3",
        "name": "Name",
        "slug": "Slug",
        "title": "Fleets"
      },
      "name": "Name",
      "operatingModes": {
        "color": "Color",
        "edit": "Edit operating mode",
        "isDefault": "Default?",
        "name": "Name",
        "title": "Operating Modes",
        "useForStatistics": "Include in statistics?"
      },
      "slug": "Id",
      "users": {
        "add": "Add user",
        "edit": "Edit user",
        "email": "Email",
        "role": "Role",
        "role_admin": "Admin",
        "role_viewer": "Viewer",
        "title": "Users"
      },
      "vehicles": {
        "add": "Add vehicle",
        "deviceIds": "Device IDs",
        "deviceIdsExample": "e.g. 5E883901, 7A046BC1",
        "edit": "Edit vehicle",
        "installedCapacity": "Installed capacity",
        "installedCapacitykW": "Installed capacity (kW)",
        "installedCapacitykWp": "Installed capacity (kWp)",
        "location": "Location",
        "model": "Model",
        "name": "Name",
        "numberPlate": "Number plate",
        "operationMode": "Operation mode",
        "route": "Route",
        "timeZone": "Time zone",
        "title": "Vehicles",
        "validFrom": "Valid data from",
        "validFromDay": "from {{from}}",
        "validity": "Data validity",
        "validUntil": "Valid data until",
        "validUntilDay": "until {{until}}",
        "vehicleType": "Vehicle type",
        "installMetaDataType": "Installed type",
        "installMetaDataMCUVersion": "Installed MCU version",
        "installMetaDataState": "Install state",
      }
    },
    "footer": {
      "imprintLabel": "Imprint",
      "imprintLink": "https://sonomotors.com/en/imprint/",
      "logoutLabel": "Logout",
      "privacyLabel": "Privacy Policy",
      "privacyLink": "https://sonomotors.com/en/privacy-policy/",
      "termsLabel": "Terms and Conditions",
      "termsLink": "https://sonomotors.com/en/downloads/terms-and-conditions/",
      "whisteblowerLabel": "Whistleblower hotline",
      "whisteblowerLink": "https://www.whistleblowerservices.com/sonogroup?language=en"
    },
    "login": {
      "emailAddress": "Email address",
      "login": "Sign in",
      "loginCode": "Login Code",
      "loginInstructions": "In order to log in, simply enter your work email address. We will then immediately send you a login link.",
      "loginLinkRequestedText": "We just emailed a magic link to {{email}}. Use the link from the email or enter the code here to sign in:",
      "loginLinkRequestedTitle": "Please check your inbox",
      "loginWithEmail": "Sign in with email",
      "pageTitle": "Login",
      "requestAgain": "Send again",
      "requestLoginLink": "Send me the link",
      "wrongCode": "Hmm, the code you entered seems to be wrong. Please try again or request a new code.",
      "noWorkMail": "Please use your work email address to log in.",
      "yourSonoAccount": "Your Sono account"
    },
    "noFleets": {
      "text": "Please check if you used the right email address or ask your fleet admin to add your email address to a fleet.",
      "title": "Nothing to see here"
    },
    "ok": "Ok"
  }
};