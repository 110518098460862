import React, { useState, useEffect } from 'react';
import { useAuth } from '../fetchers/auth';
import './login.scss';
import I18n from '../i18n';
import { useSearchParams } from 'react-router-dom';

const isCompanyEmail = (email: string): boolean => {
  const knownMailServices = [
    'gmail',
    'yahoo',
    'hotmail',
    'gmx',
    'web',
    'outlook',
    'aol',
    'strato',
    't-online',
    'freenet',
    'arcor',
    'mail',
    'icloud',
    'me',
    'mac',
    'live',
    'msn',
    'googlemail',
    'yandex',
    'protonmail',
    'zoho',
    'fastmail',
    'aol',
    'aim',
    'lycos',
    'mail',
    'inbox',
  ];
  const domain = email.split('@')[1].split('.')[0];
  return !knownMailServices.includes(domain);
};

function RequestCode({ email, setEmail, next }: { email?: string; setEmail: (e: string) => void; next: () => void }) {
  const { requestCode, isLoading } = useAuth();
  const [error, setError] = useState(false);

  return (
    <>
      <h1>{I18n.t('solar.login.loginWithEmail')}</h1>
      <p>{I18n.t('solar.login.loginInstructions')}</p>
      <input
        autoComplete="email"
        placeholder={I18n.t('solar.login.emailAddress')}
        value={email || ''}
        onChange={(e) => setEmail(e.target.value.trim())}
        required
      />
      {error && <p className="error">{I18n.t('solar.login.noWorkMail')}</p>}
      <br />
      <div className="buttons">
        <button
          disabled={!email}
          className={isLoading ? 'loading' : ''}
          onClick={async () => {
            if (!isCompanyEmail(email!)) {
              setError(true);
              return;
            }
            await requestCode(email!);
            next();
          }}
        >
          {I18n.t('solar.login.requestLoginLink')}
          <span className="spinner" />
        </button>
      </div>
    </>
  );
}

function EnterCode({ email, next, back }: { email: string; next: () => void; back: () => void }) {
  const [searchParams] = useSearchParams();

  const [code, setCode] = useState(searchParams.get('code') ?? '');
  const { login, isLoading, loginFailed } = useAuth();

  useEffect(() => {
    (async () => {
      if (email && searchParams.has('code')) {
        if (await login(email, searchParams.get('code')!)) {
          next();
        }
      }
    })();
  }, [email, searchParams, login, next]);

  return (
    <>
      <h1>{I18n.t('solar.login.loginLinkRequestedTitle')}</h1>
      <p>{I18n.t('solar.login.loginLinkRequestedText', { email })}</p>
      <input
        placeholder={I18n.t('solar.login.loginCode')}
        autoComplete="one-time-code"
        value={code}
        onChange={(e) => setCode(e.target.value.replaceAll('-', '').trim())}
        required
      />
      {loginFailed && <p className="error">{I18n.t('solar.login.wrongCode')}</p>}
      <div className="buttons">
        <button onClick={async () => (await login(email, code!)) && next()} disabled={!code || isLoading}>
          {I18n.t('solar.login.login')}
          <span className="spinner" />
        </button>
        <button onClick={back}>{I18n.t('solar.login.requestAgain')}</button>
      </div>
    </>
  );
}

export default function Login() {
  const [searchParams] = useSearchParams();

  const [codeRequested, setCodeRequested] = useState(searchParams.has('email'));
  const [email, setEmail] = useState(searchParams.get('email') ?? undefined);

  return (
    <div className="content login">
      <div className="sideImage" />
      <div className="loginForm">
        {codeRequested && email ? (
          <EnterCode back={() => setCodeRequested(false)} email={email} next={() => (window.location.href = '#/*')} />
        ) : (
          <RequestCode email={email} setEmail={setEmail} next={() => setCodeRequested(true)} />
        )}
      </div>
    </div>
  );
}
