import React from 'react';
import './infoBox.scss';

export default function InfoBox({ title, children }: React.PropsWithChildren<{ title: string }>) {
  return (
    <div className="infoBox">
      {title} <img src={'information.png'} alt="" className="infoImage" />
      <div className="popup">{children}</div>
    </div>
  );
}
