import I18n from 'i18n-js';
import moment from 'moment';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { EnergyEarnings, DataPoint, OperatingMode, VehicleStatus } from '@solar-data/schemas/lib/owned-by/solar';
import { useFleets, useOperatingModes } from '../fetchers/fleet';
import { useVehicleOperatingModes, UiVehicleOperatingMode } from '../fetchers/vehicleOperatingModes';
import { useModal } from './modal';
import { useSelectedDay } from './selectedDay';
import './dailyEnergyEarnings.scss';
import { IsGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

function kWh(kWh: number | undefined): string {
  if (kWh === undefined) {
    return '-';
  }
  return kWh.toFixed(2).replace(/\.?0*$/, '');
}

function VehicleOperatingModeDialog({
  days,
  vehicleName,
  modeId,
  reason,
  confirm,
  operatingModes,
}: {
  days: string[];
  vehicleName: string;
  modeId: number;
  reason?: string;
  confirm: (result: Partial<UiVehicleOperatingMode> | undefined) => void;
  operatingModes: OperatingMode[];
}) {
  const [newReason, setNewReason] = useState(reason || '');
  const [newModeId, setNewModeId] = useState(modeId);

  return (
    <div className="filter-dialog">
      <h2>
        {days.length === 1
          ? I18n.t(`solar.dashboard.details.operatingMode.title`, {
              day: moment(days[0]).format('ll'),
              vehicleName,
            })
          : I18n.t(`solar.dashboard.details.operatingMode.titleMultipleDays`, { vehicleName })}
      </h2>
      {operatingModes.map((om) => (
        <div key={om.id}>
          <input
            type="radio"
            name="mode"
            id={`mode-${om.id}`}
            checked={om.id === newModeId}
            onChange={(e) => (e.target.checked ? setNewModeId(om.id) : undefined)}
          />
          <label htmlFor={`mode-${om.id}`}>{om.name}</label>
        </div>
      ))}
      <textarea
        placeholder={I18n.t(`solar.dashboard.details.operatingMode.reason`)}
        defaultValue={newReason}
        onChange={(e) => setNewReason(e.target.value)}
      />
      <button onClick={() => confirm({ id: newModeId, reason: newReason })}>
        {I18n.t('solar.ok', { vehicleName })}
      </button>
      <button onClick={() => confirm(undefined)}>{I18n.t('solar.cancel', { vehicleName })}</button>
    </div>
  );
}

function Filters({ onFiltersChanged }: { onFiltersChanged: (filter: (dp: DataPoint) => boolean) => void }) {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    minkWh: undefined as number | undefined,
    maxkWh: undefined as number | undefined,
    fromDate: undefined as string | undefined,
    toDate: undefined as string | undefined,
  });

  useEffect(
    () =>
      onFiltersChanged(
        (dp: DataPoint) =>
          (filters.minkWh === undefined || dp.energykWh >= filters.minkWh) &&
          (filters.maxkWh === undefined || dp.energykWh <= filters.maxkWh) &&
          (filters.fromDate === undefined || dp.ts >= filters.fromDate) &&
          (filters.toDate === undefined || dp.ts <= filters.toDate),
      ),
    [filters],
  );

  const toNumberOrUndefined = (s: string) => (Number.isNaN(Number.parseFloat(s)) ? undefined : Number.parseFloat(s));

  return (
    <div className="filters">
      <h3 onClick={() => setShow(!show)}>
        {show ? '▼' : '▶'}{' '}
        {I18n.t('solar.dashboard.details.dailyEnergyEarnings.filters.title', {
          active:
            (filters.minkWh !== undefined || filters.maxkWh !== undefined ? 1 : 0) +
            (filters.fromDate !== undefined || filters.toDate !== undefined ? 1 : 0),
        })}
      </h3>
      {show ? (
        <>
          <label htmlFor="fromDate">{I18n.t('solar.dashboard.details.dailyEnergyEarnings.filters.date')}</label>
          <div>
            <input
              type="date"
              id="fromDate"
              onChange={(e) => setFilters({ ...filters, fromDate: e.target.value || undefined })}
            />
            {' ' + I18n.t('solar.dashboard.details.dailyEnergyEarnings.filters.to')}
            <input
              type="date"
              id="toDate"
              onChange={(e) => setFilters({ ...filters, toDate: e.target.value || undefined })}
            />
          </div>
          <label htmlFor="minkWh">{I18n.t('solar.dashboard.details.dailyEnergyEarnings.filters.kWh')}</label>
          <div>
            <input
              type="number"
              step={0.1}
              id="minkWh"
              onChange={(e) => setFilters({ ...filters, minkWh: toNumberOrUndefined(e.target.value) })}
            />
            {' ' + I18n.t('solar.dashboard.details.dailyEnergyEarnings.filters.to')}
            <input
              type="number"
              step={0.1}
              id="maxkWh"
              onChange={(e) => setFilters({ ...filters, maxkWh: toNumberOrUndefined(e.target.value) })}
            />
          </div>
        </>
      ) : undefined}
    </div>
  );
}

export default function DailyEnergyEarnings({
  energyEarnings,
  vehicleStatus,
}: {
  energyEarnings: EnergyEarnings;
  vehicleStatus: VehicleStatus;
}) {
  const modal = useModal();
  const { currentFleet, currentRole } = useFleets();
  const { vehicleOperatingModes, setMode } = useVehicleOperatingModes(currentFleet ?? '', vehicleStatus.id);
  const { operatingModes } = useOperatingModes();
  const { setSelectedDay, setDaySelected, setSelectedDays, selectedDays } = useSelectedDay();
  const [filter, setFilter] = useState(() => (dp: DataPoint) => true);

  const defaultMode = operatingModes.find((om) => om.isDefault) ?? {
    id: 0,
    color: '#ffffff',
    name: '-',
  };

  const editVehicleOperatingMode = useCallback(
    async (days: string[], vehicleName: string, vehicleOperatingMode?: UiVehicleOperatingMode) => {
      const res = await modal<Partial<UiVehicleOperatingMode> | undefined>((close) => (
        <VehicleOperatingModeDialog
          days={days}
          vehicleName={vehicleName}
          modeId={vehicleOperatingMode?.mode?.id ?? defaultMode.id}
          reason={vehicleOperatingMode?.reason}
          confirm={close}
          operatingModes={operatingModes}
        />
      ));

      if (res !== undefined) {
        await Promise.all(days.map((day) => setMode(day, res.id ?? defaultMode.id, res.reason ?? '')));
      }
    },
    [modal],
  );

  const filteredTimeline = useMemo(() => energyEarnings.timeline.filter(filter).reverse(), [filter]);

  useEffect(() => {
    const filteredSelectedDays = selectedDays.filter((d) => filteredTimeline.find((dp) => dp.ts === d));
    if (filteredSelectedDays.length < selectedDays.length) {
      setSelectedDays(filteredSelectedDays);
    }
  }, [filteredTimeline, selectedDays]);

  return (
    <div className="diagram dailyEnergyEarnings">
      <h2>{I18n.t('solar.dashboard.details.dailyEnergyEarnings.title')}</h2>
      <div className="table">
        <Filters onFiltersChanged={(f) => setFilter(() => f)} />
        <table>
          <tbody>
            {filteredTimeline
              .map(
                (dp) =>
                  [dp, vehicleOperatingModes?.find((is) => is.day === dp.ts)] as [
                    DataPoint,
                    UiVehicleOperatingMode | undefined,
                  ],
              )
              .map(([dp, vehicleOperatingMode]) => (
                <tr key={dp.ts}>
                  <td
                    className="pointer"
                    onClick={(e) => ((e.target as any).tagName === 'INPUT' ? undefined : setSelectedDay(dp.ts))}
                  >
                    <input
                      type="checkbox"
                      checked={!!selectedDays.find((sd) => sd === dp.ts)}
                      onChange={(e) => setDaySelected(dp.ts, e.target.checked)}
                    />
                    {moment(dp.ts).format('ll')}
                  </td>
                  <td className="pointer right" onClick={() => setSelectedDay(dp.ts)}>
                    {kWh(dp.energykWh)} kWh
                  </td>
                  <td
                    className="operatingMode"
                    onClick={() =>
                      currentRole === 'admin' &&
                      editVehicleOperatingMode([dp.ts], vehicleStatus.name, vehicleOperatingMode)
                    }
                  >
                    <div
                      className="operatingModeIcon"
                      style={{ backgroundColor: vehicleOperatingMode?.mode?.color ?? defaultMode.color }}
                    />
                    {vehicleOperatingMode?.mode?.name ?? defaultMode.name}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="all">
        <span>
          <input
            type="checkbox"
            id="selectAll"
            onChange={(e) =>
              setSelectedDays((e.target as HTMLInputElement).checked ? filteredTimeline.map((dp) => dp.ts) : [])
            }
            checked={selectedDays.length === filteredTimeline.length}
          />
          <label htmlFor="selectAll">{I18n.t('solar.dashboard.details.dailyEnergyEarnings.all')}</label>
        </span>
        <IsGranted roles={[ROLE.CAN_EDIT_OPERATION_MODES]}>
          <button
            disabled={selectedDays.length === 0}
            onClick={() => editVehicleOperatingMode(selectedDays, vehicleStatus.name)}
          >
            {I18n.t('solar.dashboard.details.dailyEnergyEarnings.editOperatingMode')}
          </button>
        </IsGranted>
      </div>
    </div>
  );
}
